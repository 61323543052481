.calculator_input_container {
    display: flex;
    flex-flow: column;
    align-items: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.calculator_body_container {
  align-items: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.toast_notifications {
  position: sticky;
}